.c-service-item-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 48%;
    margin: 5px;
    height: 100px;
    background-color: $color-light-grey;
    height: 60px;
    border-radius: 10px;
}

.c-service-item-name {
    color: $color-blue;
    font-size: 20px;
    line-height: 25px;
    margin-left: 20px;
    max-width: 50%;
}

.c-service-item-phone {
    color: $color-black;
    font-size: 20px;
    line-height: 25px;
    margin-right: 20px;
    max-width: 50%;
}

.c-service-item-phone-single-item {
    color: $color-black;
    font-size: 20px;
    line-height: 25px;
    margin-right: 280px;
    max-width: 50%;
}

.c-service-item-link {
    color: $color-blue;
    font-weight: bold;
    cursor: pointer;
}