.t-semibold__16 {
  font-weight: 600;
  font-size: 16px;
}

.t-semibold__26 {
  font-weight: 600;
  font-size: 26px;
}

.t-base__18 {
  font-size: 18px;
}

.t-base__20 {
  font-size: 20px;
}

.t-base__20-30 {
  font-size: 20px;
  line-height: 30px;
}

.t-base__26 {
  font-size: 26px;
}

.t-bold__26 {
  font-size: 26px;
  font-weight: bold;
}

.t-base__30 {
  font-size: 30px;
}

.t-semibold__20 {
  font-size: 20px;
  font-weight: 600;
}

.t-semibold__40 {
  font-size: 40px;
  font-weight: 600;
}