// background radial out effect, specify for button grid
// design spec: animation should be completed in 200ms
// grid button: 460 x 400, wide/length ratio is 1:1.15 for a circle
.animate__button-grid::before {
  position: absolute;
  z-index: $zindex-pseudo; // make pseduo element behind parent
  content: "";
  background-color: $color-black;
  opacity: 0;
  border-radius: 50%;
  transform: scale(0.2, 0.23);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: 250ms ease 1 radialout forwards;
}

@keyframes radialout {
  0% { transform: scale(0.2, 0.23); opacity: 0 }
  80% { transform: scale(1.34, 1.541); opacity: 0.2 }
  100% { transform: scale(1.34, 1.541); opacity: 0.2; }
}
  
// fade-in effect
.animate__fade-in {
  opacity: 0;
  animation: 300ms ease-out 1 fadein forwards;
}

@keyframes fadein {
  0% { opactiy: 0; }
  100% { opacity: 1; }
}

//
// Design direct port section
//

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #022DB7;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
