// 
// notes: please update the color values in /constans/appConstants if any value is modified in this file
// 

$color-black: #000000;
$color-white: #ffffff;
$color-blue: #022db7;
$color-sky-blue: #00a5df;
$color-green: #2cc84d;
$color-yellow: #ffcd00;
$color-red: #ee2737;
$color-dark-grey: #262626;
$color-shadow-blue: #001452;
$color-light-grey: #FAFAFA;
$color-page-description: #4D4D4D;

.color-black {
    color: $color-black;
}

.color-white {
    color: $color-white;
}

.color-blue {
    color: $color-blue;
}

.color-sky-blue {
    color: $color-sky-blue;
}

.color-green {
    color: $color-green;
}

.background-color-sky-blue {
    background-color: $color-sky-blue;
}

.background-color-green {
    background-color: $color-green;
}

.background-color-yellow {
    background-color: $color-yellow;
}

.background-color-red {
    background-color: $color-red;
}

.color-dark-grey {
    color: $color-dark-grey;
}

.color-shadow-blue {
    color: $color-shadow-blue;
}

.color-light-grey {
    color: color-light-grey;
}

.color-page-description {
    color: color-page-description;
}
