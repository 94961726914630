body {
  margin: 0;
  font-family: 'Sharp Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  cursor: pointer;
  text-decoration: none;
}

div {
  box-sizing: border-box;
}

span, div {
  user-select: none;
}

$zindex-background: -1;
$zindex-pseudo: -1;
$zindex-button-grid: 1;
$zindex-bottom-tab-bar: 10;
$zindex-modal: 11;

$height-navigation-controls: 100px;
$height-welcome-normal-header: 200px;
$height-welcome-home-header: 350px;
$height-bottom-tab-bar: 180px;
$height-bottom-legal-bar: 100px;
