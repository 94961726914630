.p-page {
}

.fakeLink {
    color: $color-blue;
    font-weight: bold;
}

.p-page-header-image {
    width: 100%;
}

.p-page-content {
    background-color: $color-white;
    padding-left: 68px;
    padding-right: 70px;
    padding-top: 40px;
    margin-top: -3px;
}

.p-page-header {
    color: $color-black;
    font-size: 40px;
    line-height: 50px;
}

.p-page-subheader {
    color: $color-black;
    font-size: 20px;
    line-height: 25px;
}

.p-page-description {
    color: $color-page-description;
    font-size: 20px;
    line-height: 30px;
}

.p-page-flex-box {
    display: flex;
    flex-wrap: wrap;
}

.p-page-shuttle-map-container {
    height: 700px;
}

.p-page-flex-routes-item {
    flex: 1 0 48%;
    margin: 5px;
    height: 100px;
}

.p-page-flex-routes-item-text {
    color: $color-page-description;
    font-size: 20px;
    line-height: 30px;
}

.p-page-flex-routes-item-image {
    width: 100%;
}

.p-image {
    width: 100%;
}

.p-airport-legend {
    margin-top: 55px;
    margin-bottom: 100px;
}



