.o-link-button {
  position: relative;
  overflow: hidden;
  z-index: $zindex-button-grid;
  display: flex;
  width: 460px;
  height: 400px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 20px 30px $color-shadow-blue;
  text-decoration: none;
  color: $color-black;
  padding: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.o-airport-link-button {
  display: flex;
  width: 940px;
  height: 460px;
  cursor: pointer;
  border-radius: 12px;
  box-shadow: 0px 20px 30px $color-shadow-blue;
  text-decoration: none;
  margin-top: 20px;
  color: $color-black;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: url('../assets/images/TouchHerefortheDirectory.png');
  z-index: $zindex-button-grid;
}
  
.o-back-button {
  cursor: pointer;
  height: 80px;
  width: 180px;
  padding-top: 16px;
  align-self: center;
}

.o-feedback-button {
  justify-self: end;
  display: flex;
  cursor: pointer;
  height: 50px;
  width: 250px;
  padding-left: 30px;
  padding-right: 30px;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  background-color: $color-yellow;
  border-radius: 5px;
}

.o-small-feedback-arrow {
  width: 36px;
  height: 30px;
}

.o-small-back-arrow {
  width: 30px;
  height: 30px;
}

.o-tab-button {
  height: 180px;
  width: 180px;
  min-width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 1px solid $color-dark-grey;
  border-right: 1px solid $color-dark-grey;
}

.o-tab-button-line {
  position: absolute;
  bottom: 0;
  width: 178px;
  height: 10px;
}

.o-new-airport-banner-logo {
  position: absolute;
  bottom: 40px;
  left: 40px;
}

.o-new-airport-banner-legend {
  color: $color-yellow;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 70px;
  position: absolute;
  bottom: 19px;
  right: 42px;
}

.o-opacity__1 {
  opacity: 1;
}

.o-opacity__0 {
  opacity: 0;
}

.o-lost-found-backdrop {
  position: absolute;
  width: 1080px;
  height: 1460px;
  z-index: $zindex-background;
}
