@font-face {
    font-family: 'Sharp Grotesk';
    src: local('Sharp Grotesk'), local('SharpGrotesk'),
    url(../assets/fonts/SharpGroteskBook20.eot) format('opentype'),
    url(../assets/fonts/SharpGroteskBook20.woff) format('woff'),
    url(../assets/fonts/SharpGroteskBook20.woff2) format('woff2');
    font-style: normal;
  }

  @font-face {
    font-family: 'Sharp Grotesk';
    src: local('Sharp Grotesk'), local('SharpGrotesk'),
    url(../assets/fonts/SharpGroteskSmBold20.eot) format('opentype'),
    url(../assets/fonts/SharpGroteskSmBold20.woff) format('woff'),
    url(../assets/fonts/SharpGroteskSmBold20.woff2) format('woff2');
    font-style: normal;
    font-weight: bold;
  }

.bold {
    font-weight: bold;
}